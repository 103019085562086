import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const TheShoyaright = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="The Shoyaright! Volume 4" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <div id="shoyarightChomsky" />
      <div id="lastCat" className="categories">
        MUSIC POLITICS CINEMA ART TELEVISION CUISINE TECHNOLOGY SPORTS NATURE
      </div>
      <div className="articleList">
        <p>
          <Link to="/shoyaright/vol4/pope-sean-paul">Pope Sean Paul</Link>
        </p>
        <p>
          <Link to="/shoyaright/vol4/the-iron-in-your-soul">
            The Iron In Your Soul
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol4/free-palestine">Free Palestine</Link>
        </p>
        <p>
          <Link to="/shoyaright/vol4/filmmaker-starting-5">
            Filmmaker Starting 5
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol4/nardwuar-must-go">Nardwuar Must Go</Link>
        </p>
        <p>
          <Link to="/shoyaright/vol4/zuff-gpt-2">
            ZuffGPT-2; or, How to Defeat AI
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol4/zuff-gpt">
            ZuffGPT; or, How to Survive AI
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol4/führer-king-waka">Führer King Waka</Link>
        </p>
        <p className="locked">Boredom: A Particle Collision</p>
        <p className="locked">Burners & Fast Ignitions</p>
        <p className="locked">Fairmount Park & You</p>
        <p className="locked">Final Public Offering</p>
        <p>
          <Link to="/shoyaright/volumes">INDEX</Link>
        </p>
      </div>
      <div className="copyright">
        <p>&copy; Shoya, Inc. 2020 - &infin;</p>
      </div>
    </div>
  )
}

export default TheShoyaright
